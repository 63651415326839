import { Select } from 'components/global/FormInputs'
import Modal from 'components/global/Modal'
import SectionTitle from 'components/global/SectionTitle'
import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import FlipMove from 'react-flip-move'
// @ts-ignore
import Fade from 'react-reveal/Fade'
// @ts-ignore
import Zoom from 'react-reveal/Zoom'

import DigitalResoucesKey from './DigitalResourcesKey'
import ThemeCard from './ThemeCard/ThemeCard'
import ThemeChartEla from './ThemeChart/ThemeChartEla'
import ThemeChartGenre from './ThemeChart/ThemeChartGenre'
import ThemeChartResearchLab from './ThemeChart/ThemeChartResearchLab'
import ThemeChartSpanish from './ThemeChart/ThemeChartSpanish'
import ThemeChartSpanishGenre from './ThemeChart/ThemeChartSpanishGenre'
import ThemeChartTextSet from './ThemeChart/ThemeChartTextSets'

interface Props {
	themeType: 'Research Lab' | 'Spanish' | 'Spanish Genre' | 'Genre' | 'ELA' | 'Text Set'
	sectionName: string
	gray?: boolean
	globalGrade: string
	globalSubject: string
}

const Themes = ({ themeType, sectionName, gray, globalGrade, globalSubject }: Props) => {
	const [openThemeChart, setOpenThemeChart] = useState(true)
	const [openThemes, setOpenThemes] = useState(true)
	const [subject, setSubject] = useState(globalSubject)
	const [grade, setGrade] = useState(globalGrade)

	const grades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
	const subjects = ['Life Science', 'Earth & Space Science', 'Physical Science', 'World History', 'American History', 'World Geography & Cultures']

	useEffect(() => {
		setSubject(globalSubject)
	}, [globalSubject])

	useEffect(() => {
		setGrade(globalGrade)
	}, [globalGrade])

	const [modalOpen, setModalOpen] = useState(false)
	const [themeName, setThemeName] = useState('')
	const [themeGrade, setThemeGrade] = useState<string[]>([])

	const handleClose = () => {
		setModalOpen(false)
	}

	const passDataToModal = (name: string, grade: string[]) => {
		setModalOpen(true)
		setThemeName(name)
		setThemeGrade(grade)
	}

	const handleThemeChartOpenClose = () => {
		if (openThemeChart) {
			setOpenThemeChart(false)
			scrollTo(`#${themeType.replace(' ', '-').toLowerCase()}-start`)
		} else setOpenThemeChart(true)
	}

	const queryData = useStaticQuery<ThemeCardQuery>(query).allContentfulTheme.edges
	const data = queryData.filter((e) => e.node.themeType === themeType)

	let themeFilter: ThemeCardQueryNode[]
	if (subject === 'all' && grade === 'all') themeFilter = data.filter((theme) => theme.node.themeSubject !== subject && !theme.node.grades.includes(grade))
	else if (subject === 'all') themeFilter = data.filter((theme) => theme.node.themeSubject !== subject && theme.node.grades.includes(grade))
	else if (grade === 'all') themeFilter = data.filter((theme) => theme.node.themeSubject === subject && !theme.node.grades.includes(grade))
	else themeFilter = data.filter((theme) => theme.node.themeSubject === subject && theme.node.grades.includes(grade))

	let themeChart
	if (themeType === 'Research Lab') themeChart = <ThemeChartResearchLab themes={themeFilter} />
	else if (themeType === 'Spanish') themeChart = <ThemeChartSpanish themes={themeFilter} />
	else if (themeType === 'Spanish Genre') themeChart = <ThemeChartSpanishGenre />
	else if (themeType === 'Genre') themeChart = <ThemeChartGenre />
	else if (themeType === 'ELA') themeChart = <ThemeChartEla />
	else if (themeType === 'Text Set') themeChart = <ThemeChartTextSet themes={themeFilter} />

	return (
		<React.Fragment>
			<Zoom>
				<SectionTitle title={sectionName} />
			</Zoom>
			<Row>
				<Col className="mb-4 flex justify-start md:hidden">
					<Fade>
						<Button size="sm" onClick={() => setOpenThemes(!openThemes)}>
							{openThemes ? 'Collapse Section' : 'Expand Section'}
						</Button>
					</Fade>
				</Col>
			</Row>
			<Collapse
				isOpened={openThemes}
				theme={{
					collapse: 'transition-all duration-500 ease-in-out',
					content: 'content'
				}}
			>
				<Row id={`${themeType.replace(' ', '-').toLowerCase()}-start`} className="items-center">
					<Col>
						<Fade>
							<Button onClick={() => setOpenThemeChart(!openThemeChart)} aria-controls="theme-chart-collapse" aria-expanded={openThemeChart}>
								{openThemeChart ? 'Hide Theme Chart' : 'See Full Theme Chart'}
							</Button>
						</Fade>
					</Col>
				</Row>
				<div style={{ height: `${openThemeChart ? 'auto' : '256px'}`, overflow: `${openThemeChart ? 'inital' : 'hidden'}`, position: 'relative' }}>
					<Row>
						<Col>
							<DigitalResoucesKey />
							<div className="overflow-x-auto">{themeChart}</div>
						</Col>
					</Row>
					<div
						className={`${gray ? 'gray-transparent-gradient' : 'white-transparent-gradient'} ${
							openThemeChart ? 'z-[-1] opacity-0' : 'z-10 opacity-100'
						} absolute top-0 flex w-full items-end justify-center transition-opacity duration-200 ease-in-out`}
						style={{ height: 256 }}
					>
						<button className={`mb-6 ${openThemeChart ? 'hidden' : 'block'}`} onClick={handleThemeChartOpenClose}>
							<svg xmlns="http://www.w3.org/2000/svg" className="mr-1 inline h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2.5}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
							</svg>{' '}
							See Full Theme Chart{' '}
							<svg xmlns="http://www.w3.org/2000/svg" className="ml-1 inline h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2.5}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button>
					</div>
					<div className="flex w-full justify-center">
						<button className={`${openThemeChart ? 'block' : 'hidden'}`} onClick={handleThemeChartOpenClose}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="mr-1 inline h-4 w-4 rotate-180"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2.5}
							>
								<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
							</svg>{' '}
							Hide Full Theme Chart{' '}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="ml-1 inline h-4 w-4 rotate-180"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2.5}
							>
								<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
							</svg>
						</button>
					</div>
				</div>
				<Row>
					<Col className="mt-3 flex items-center justify-start sm:mt-0 md:justify-end">
						<svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5 text-slate-500" viewBox="0 0 20 20" fill="currentColor">
							<path
								fillRule="evenodd"
								d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
								clipRule="evenodd"
							/>
						</svg>
						{themeType === 'Genre' || themeType === 'ELA' || themeType === 'Spanish Genre' ? null : (
							<div className="flex items-center">
								<Select
									id={`${sectionName}-subject-select`}
									name="subject"
									value={subject}
									onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSubject(e.target.value)}
								>
									<option value="all">All Subjects</option>
									{subjects.map((subject) => {
										return (
											<option value={subject} key={`${themeType}-${subject}`}>
												{subject}
											</option>
										)
									})}
								</Select>
							</div>
						)}
						<div className="ml-3 flex items-center">
							<Select id={`${sectionName}-grade-select`} name="grade" value={grade} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setGrade(e.target.value)}>
								<option value="all">K-12</option>
								{grades.map((grade) => {
									return (
										<option value={grade} key={`${themeType}-${grade}`}>
											{grade}
										</option>
									)
								})}
							</Select>
						</div>
					</Col>
					<Col>
						<DigitalResoucesKey />
					</Col>
				</Row>
				<Row>
					{/* @ts-ignore */}
					<FlipMove typeName={null}>
						{themeFilter.length > 0 ? (
							themeFilter.map(({ node }) => <ThemeCard theme={node} key={node.id} passDataToModal={passDataToModal} />)
						) : (
							<p className="mx-auto mt-8 font-semibold">Sorry, no themes match that filter.</p>
						)}
					</FlipMove>
				</Row>
			</Collapse>
			<Modal title={themeName} isOpen={modalOpen} handleClose={handleClose}>
				{themeGrade && (
					<p>
						The {themeName} theme is currently available for {themeGrade.length > 1 ? 'grades' : 'grade'}{' '}
						{themeGrade.map((grade, i, grades) => {
							if (grades.length > 2) {
								return <span key={i}>{i !== grades.length - 1 ? `${grade}, ` : `and ${grade}`}</span>
							} else if (grades.length === 2) {
								return <span key={i}>{i === 0 ? `${grade} and ` : grade}</span>
							} else {
								return <span key={i}>{grade}</span>
							}
						})}{' '}
						in SchoolPace Connect.
					</p>
				)}
			</Modal>
		</React.Fragment>
	)
}

export interface IThemeCard {
	id: string
	name: string
	slug: string
	themeType: string
	themeSubject: string
	gradeLevel: string
	grades: string[]
	eLibrary: string
	digitalResources: string
	hasResearchLab: boolean
	basketLevels: string[]
	libraryDisclaimer: string
	schoolPaceConnectGrades: string[]
	description: {
		description: string
	}
	coverImage: {
		gatsbyImageData: IGatsbyImageData
	}
}

export interface ThemeCardQueryNode {
	node: IThemeCard
}

interface ThemeCardQuery {
	allContentfulTheme: {
		edges: ThemeCardQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulTheme(sort: { fields: [name], order: ASC }, filter: { removeInThemePagesBuild: { ne: true } }) {
			edges {
				node {
					id
					name
					slug
					themeType
					themeSubject
					gradeLevel
					grades
					eLibrary
					digitalResources
					hasResearchLab
					basketLevels
					libraryDisclaimer
					schoolPaceConnectGrades
					description {
						description
					}
					coverImage {
						gatsbyImageData
					}
				}
			}
		}
	}
`

export default Themes
